import { Injectable } from '@angular/core';
import { Orchestra } from '../shared/models/orchestra.model';
import { Notification } from '../shared/models/notification.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { Venue } from '../shared/models/venue.model';
import { DatePipe } from '@angular/common';
import { ConcertImage } from '../shared/models/concertimage.model';
import { Concert } from '../shared/models/concert.model';
import { ConcertSubscription } from '../shared/models/subscription.model';
import { Category } from '../shared/models/category.model';
import { City } from '../shared/models/city.model';
import { FilenameSlugifyPipe } from '../pipes/filename-slugify.pipe';

interface Occasion {
  event_time: Date,
  venue: Venue,
  ticket_url: string,
  registration: boolean,
  stream_url: string,
  subscriptions: ConcertSubscription[],
  duplicateWarning: boolean,
  sametimeWarning: boolean,
  cancelled: boolean,
  id: number
}

interface OccasionToUpload {
  event_time: string,
  venue: Venue,
  ticket_url: string,
  registration: boolean,
  stream_url: string,
  subscriptions: number[],
  id: number,
  cancelled: boolean
}

interface UserData {
  id: number,
  name: string,
  user_email: string,
  first_name: string,
  last_name: string,
  phone: string,
  company: string,
  position: string,
  company_email: string,
  extra_info: string
}

@Injectable({
  providedIn: 'root'
})

export class UserService {

  userPerformers = new BehaviorSubject<Orchestra[]>(undefined);
  notificationCount = new BehaviorSubject<number>(0);
  notificationList = new BehaviorSubject<Notification[]>(undefined);
  notificationLoading = new BehaviorSubject<boolean>(false);

  selectedPerformer = new BehaviorSubject<number>(0);
  snackBarMessage = new BehaviorSubject<string>(undefined);

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private datePipe: DatePipe,
    private filenameSlugifyPipe: FilenameSlugifyPipe
    ) { }

  createHeader(){
    return {'Authorization': `Token ${this.authService.getToken()}`};
  }

  listPerformers(): Observable<Orchestra[]> {
    return this.http.get<Orchestra[]>(`${environment.manager}/performers/user/`, {'headers':this.createHeader()});
  }

  updateUserProfile(user_data: UserData): Observable<any> {
    const body = JSON.stringify(user_data);
    const headers = {'content-type': 'application/json',
    'Authorization': `Token ${this.authService.getToken()}`
  };
    return this.http.patch<any>(`${environment.users}/user-profile/${user_data.id}/`, body, {'headers':headers});
  }

  addPerformer(performer: Performer, category_id: number, new_category: string): Observable<any> {
    const formData = new FormData();
    formData.append('name', performer.name);
    formData.append('abbr', performer.abbr);
    let cover = 'true'
    if (!performer.cover)
      cover = ''
    formData.append('cover', cover);
    formData.append('category_id', category_id.toString());
    if (!category_id)
      formData.append('new_category', new_category);
    if (performer.logo && performer.logo.name)
      formData.append('logo', performer.logo, this.filenameSlugifyPipe.transform(performer.logo.name));
    return this.http.post<any>(`${environment.manager}/performers/user/`, formData, {'headers':this.createHeader()});
  }

  modifyPerformer(performer, category_id: number, new_category: string): Observable<any> {
    const formData = new FormData();
    formData.append('name', performer.name);
    formData.append('abbr', performer.abbr);
    if (!performer.cover)
      performer.cover = '';
    formData.append('cover', performer.cover.toString());
    formData.append('category_id', category_id.toString());
    if (!category_id)
      formData.append('new_category', new_category);
    if (performer.logo && performer.logo.name)
      formData.append('logo', performer.logo, this.filenameSlugifyPipe.transform(performer.logo.name));
    return this.http.patch<any>(`${environment.manager}/performers/user/${performer.id}/`, formData, {'headers':this.createHeader()});
  }

  getNotificationCount(): Observable<number> {
    return this.http.get<number>(`${environment.users}/notifications/count/user/`, {'headers':this.createHeader()});
  }



  listNotifications(): Observable<Notification[]> {
    return this.http.get<Notification[]>(`${environment.users}/notifications/user/`, {'headers':this.createHeader()});
  }

  updateNotification(id: number): Observable<any> {
    const formData = new FormData();
    formData.append('id', id.toString());
    return this.http.patch<any>(`${environment.users}/notifications/user/`, formData, {'headers':this.createHeader()});
  }

  markSeenNotifications(ids: number[]): Observable<any> {
    const formData = new FormData();
    formData.append('ids', ids.toString());
    return this.http.put<any>(`${environment.users}/notifications/user/`, formData, {'headers':this.createHeader()});
  }

  deletePerformer(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.manager}/performers/user/${id}/`, {'headers':this.createHeader()});
  }

  unDeletePerformer(id: number): Observable<any> {
    const formData = new FormData();
    formData.append('action', 'undelete');
    return this.http.put<any>(`${environment.manager}/performers/user/${id}/`, formData, {'headers':this.createHeader()});
  }

  listPerformerVenues(id): Observable<Venue[]> {
    return this.http.get<Venue[]>(`${environment.manager}/venues/performer/${id}/`, {'headers':this.createHeader()});
  }

  listCityVenues(id): Observable<Venue[]> {
    return this.http.get<Venue[]>(`${environment.manager}/venues/city/${id}/`, {'headers':this.createHeader()});
  }

  getConcert(url: string, uuid: string = null): Observable<Concert> {
	  return this.http.get<Concert>(`${environment.manager}/concerts/${url}`,
    {'headers':this.createHeader()});
  }

  addCity(name: string, country_id: number): Observable<City> {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('country_id', country_id.toString());
    return this.http.post<City>(`${environment.manager}/add-city/`, formData, {'headers':this.createHeader()});
  }
  
  addVenue(
    location_id: Number, location_name: string, location_address: string, venue_name: string, venue_address: string, city_id: number
    ): Observable<Venue> {
    const formData = new FormData();
    formData.append('location_id', location_id.toString());
    if(location_name) {
      formData.append('location_name', location_name);
      formData.append('location_address', location_address);
    }
    if(venue_name)
      formData.append('venue_name', venue_name);
    if(venue_address)
      formData.append('venue_address', venue_address);
    return this.http.post<Venue>(`${environment.manager}/venues/city/${city_id}/`, formData, {'headers':this.createHeader()});
  }

  listPerformerSubs(id: number): Observable<ConcertSubscription[]> {
    return this.http.get<ConcertSubscription[]>(`${environment.manager}/subscriptions/performer/${id}/`, {'headers':this.createHeader()});
  }

  listPerformersWithSub(performer_id: number): Observable<Orchestra[]> {
    return this.http.get<Orchestra[]>(`${environment.manager}/performers/user/${performer_id}/`, {'headers':this.createHeader()});
  }

  checkOccasion(venue_id: number, event_time: Date, id: number = 0): Observable<boolean> {
    const time = this.datePipe.transform(event_time, 'yyyy-MM-dd-H-mm');
    return this.http.get<boolean>(`${environment.manager}/check-occasion/${venue_id}/${time}/${id}/`, {'headers':this.createHeader()});
  }

  transformOccasions(occasions: Occasion[]) {
    let occasions_to_upload: OccasionToUpload[] = [];
    occasions.forEach( (occ) => {
      let subscriptions: number[] = [];
      occ.subscriptions.forEach( (sub) => subscriptions.push(sub.id));
      let occ_to_upload: OccasionToUpload = {
        event_time: '',
        venue: occ.venue,
        ticket_url: occ.ticket_url,
        registration: occ.registration,
        stream_url: occ.stream_url,
        subscriptions: subscriptions,
        cancelled: occ.cancelled,
        id: occ.id
      };
      occ_to_upload.event_time = this.datePipe.transform(occ.event_time, 'yyyy-MM-dd-H-mm');
      occasions_to_upload.push(occ_to_upload);
    });
    return occasions_to_upload;
  }

  getPerformerImages(performer_id: number): Observable<ConcertImage[]> {
    return this.http.get<ConcertImage[]>(
      `${environment.manager}/images/performer/${performer_id}/`,
      {'headers':this.createHeader()}
      );
  }

  saveConcert(
      modify: boolean,
      performer_id: number,
      title: string,
      image_id: number,
      short_desc: string,
      long_desc: string,
      occasions: Occasion[]
    ): Observable<any> {
    
    let occasions_to_upload: OccasionToUpload[] = [];
    
    occasions_to_upload = this.transformOccasions(occasions);

    const formData = new FormData();
    formData.append('title', title);
    if (image_id)
      formData.append('image', image_id.toString());
    if (short_desc)
      formData.append('short_desc', short_desc);
    if (long_desc)
      formData.append('long_desc', long_desc);
    formData.append('occasions', JSON.stringify(occasions_to_upload));
    if (modify)
      return this.http.patch<any>(
        `${environment.manager}/concerts/performer/${performer_id}/`,
        formData,
        {'headers':this.createHeader()}
        );
    else
      return this.http.post<any>(
        `${environment.manager}/concerts/performer/${performer_id}/`,
        formData,
        {'headers':this.createHeader()}
        );
  }

  uploadImage(performer_id: number, image): Observable<ConcertImage> {
    const formData = new FormData();
    formData.append('image', image, this.filenameSlugifyPipe.transform(image.name));
    return this.http.post<ConcertImage>(
      `${environment.manager}/images/performer/${performer_id}/`,
      formData,
      {'headers':this.createHeader()}
      );
  }

  getPerformerConcerts(performer_id: number = 0, past: number = 0): Observable<Concert[]> {
    return this.http.get<Concert[]>(
      `${environment.manager}/concerts/performer/${performer_id}/past/${past}/`,
      {'headers':this.createHeader()}
      );
  }

  deleteConcert(id: number, action: string): Observable<any> {
    return this.http.delete<any>(`${environment.manager}/concerts/${action}/${id}/`, {'headers':this.createHeader()});
  }

  unDeleteConcert(id: number, action: string): Observable<any> {
    const formData = new FormData();
    return this.http.put<any>(`${environment.manager}/concerts/${action}/${id}/`, formData, {'headers':this.createHeader()});
  }

  addSubscription(
    name: string,
    season_ticket: boolean,
    season_ticket_url: string = '',
    short_desc: string = '',
    performer_id: number
    ): Observable<ConcertSubscription> {
    const season_ticket_value = season_ticket ? '1' : '0'
    const formData = new FormData();
    formData.append('name', name);
    formData.append('season_ticket', season_ticket_value);
    formData.append('season_ticket_url', season_ticket_url);
    formData.append('description', short_desc);
    return this.http.post<ConcertSubscription>(`${environment.manager}/subscriptions/performer/${performer_id}/`, formData, {'headers':this.createHeader()});
  }

  modifySubscription(
    subscription_id: number,
    name: string,
    season_ticket: boolean,
    season_ticket_url: string = '',
    short_desc: string = ''
    ): Observable<any> {
    const season_ticket_value = season_ticket ? '1' : '0'
    const formData = new FormData();
    formData.append('name', name);
    formData.append('season_ticket', season_ticket_value);
    formData.append('season_ticket_url', season_ticket_url);
    formData.append('description', short_desc);
    return this.http.patch<any>(`${environment.manager}/subscriptions/${subscription_id}/`, formData, {'headers':this.createHeader()});
  }

  deleteSubscription(subscription_id: number): Observable<any> {
    return this.http.delete<any>(`${environment.manager}/subscriptions/${subscription_id}/`, {'headers':this.createHeader()});
  }

  listPerformerCategories(): Observable<Category[]> {
    return this.http.get<Category[]>(
      `${environment.api}/categories/`,
      {'headers':this.createHeader()}
      );
  }

  getPerformer(slug: string): Observable<any> {
	  return this.http.get<any>(
      `${environment.manager}/performer/${slug}/`,
      {'headers':this.createHeader()}
    );
  }

  runScraper(performer_id: number): Observable<any> {
	  return this.http.get<any>(
      `${environment.manager}/run-scraper/${performer_id}/`,
      {'headers':this.createHeader()}
    );
  }

  getScraperInfo(performer_id: number): Observable<any> {
	  return this.http.get<any>(
      `${environment.manager}/scraper-info/${performer_id}/`,
      {'headers':this.createHeader()}
    );
  }

  stopScraper(performer_id: number): Observable<any> {
	  return this.http.delete<any>(
      `${environment.manager}/scraper-info/${performer_id}/`,
      {'headers':this.createHeader()}
    );
  }

  restartScraper(performer_id: number): Observable<any> {
    const formData = new FormData();
	  return this.http.put<any>(
      `${environment.manager}/scraper-info/${performer_id}/`,
      formData,
      {'headers':this.createHeader()}
    );
  }

  sendQuoteMessage(performer_id: number, subject: string, email: string, url:string, message: string): Observable<any> {
    const formData = new FormData();
    formData.append('subject', subject);
    formData.append('email', email);
    if (url)
      formData.append('url', url);
    if (message)
      formData.append('message', message);
    return this.http.post<any>(
      `${environment.manager}/performer/${performer_id}/get-quote/`,
      formData,
      {'headers':this.createHeader()}
      );
  }

  listSubscriptionConcerts(subscription_id: number = 0): Observable<Concert[]> {
    return this.http.get<Concert[]>(
      `${environment.manager}/subscription/${subscription_id}/concerts/`,
      {'headers':this.createHeader()}
      );
  }

  removeConcertFromSub(concert_id: number, subscription_id: number = 0): Observable<Concert[]> {
    return this.http.delete<Concert[]>(
      `${environment.manager}/subscription/${subscription_id}/concerts/${concert_id}/`,
      {'headers':this.createHeader()}
      );
  }

  addConcertToSub(concert_id: number, subscription_id: number = 0): Observable<Concert[]> {
    return this.http.patch<Concert[]>(
      `${environment.manager}/subscription/${subscription_id}/concerts/${concert_id}/`,
      '',
      {'headers':this.createHeader()}
      );
  }

  setDefaultLocation(location_data: Object): Observable<any> {
    const body = JSON.stringify(location_data);
    const headers = {'content-type': 'application/json',
    'Authorization': `Token ${this.authService.getToken()}`
    };
    return this.http.patch<any>(`${environment.users}/default-location/`, body, {'headers':headers});
  }

  addFavorite(obj_type: string, obj_id: number): Observable<any> {
	  return this.http.post<any>(
      `${environment.users}/favorites/${obj_type}/${obj_id}/`,
      '',
      {'headers':this.createHeader()}
    );
  }

  removeFavorite(obj_type: string, obj_id: number): Observable<any> {
	  return this.http.delete<any>(
      `${environment.users}/favorites/${obj_type}/${obj_id}/`,
      {'headers':this.createHeader()}
    );
  }
}





interface Performer {
  name: string,
  abbr: string,
  logo: any,
  cover: boolean
}
